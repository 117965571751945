import { useSpring, animated } from 'react-spring';
import React from 'react';
import Title from "components/Title";
import Carousel from "components/common/MultiCarousel";
import { SwiperSlide } from "swiper/react";
import cn from "classnames";

export function TechnoBlock({ img }) {
  return (
    <div className="flex flex-col items-center py-6 justify-between bg-rose-400 rounded-xl h-full p-8 w-full">
      <h1 className="text-gray-50 pb-4 text-4xl font-bold">Progress preview in a <br /> fun way, yes, it's fun</h1>
      <img className='w-72 rounded-t-xl' src={img} alt="ss" />
      <p className="text-gray-50 text-base">Tired of coming across tasks with this plain old row styles ? <br /> That
        boring preview just doesn't cut it !</p>
    </div>
  )
}

const data = [
  {
    title: "Chatbot building ",
    img: "./assets/images/team.jpg",
    content: "Harness the power of data to make informed decisions and gain a competitive edge. Join us in your journey to transform raw data into valuable insights."
  },
  {
    title: "Data scraping",
    img: "./assets/images/scrap.jpg",
    content: "Harness the power of data to make informed decisions and gain a competitive edge. Join us in your journey to transform raw data into valuable insights."
  },
  {
    title: "Big data",
    img: "./assets/images/big-data.jpg",
    content: "With our expertise in big data analytics, we empower organizations to extract meaningful insights, make data-driven decisions, and drive innovation."
  },
  {
    title: "SEO Optimization",
    img: "./assets/images/seo.jpg",
    content: "Our team of SEO experts employs cutting-edge strategies to enhance your website's search engine rankings. We meticulously analyze, optimize."
  },
  {
    title: "Manchine learning",
    img: "./assets/images/machine-learning.jpg",
    content: "Our AI solution is your gateway to the future. With cutting-edge technology and expert guidance, we're here to transform the way you work, innovate, and thrive."
  },
  {
    title: "Cloud et herbergement",
    img: "./assets/images/cloud-f165cbdd.webp",
    content: "Our AI solution is your gateway to the future. With cutting-edge technology and expert guidance, we're here to transform the way you work, innovate, and thrive."
  },
  {
    title: "Apps development",
    img: "./assets/images/mobile-app-596e7007.webp",
    content: "Experience the difference with our top-tier app development services. Our team of skilled developers is committed to turning your app ideas into reality"
  },
  {
    title: "Web development",
    img: "./assets/images/application-d42d2b0e.webp",
    content: "Our team of web development experts specializes in crafting user-friendly, responsive, and highly functional websites."
  },
  {
    title: "Data science",
    img: "./assets/images/data-science.jpg",
    content: "Our AI solution is your gateway to the future. With cutting-edge technology and expert guidance, we're here to transform the way you work, innovate, and thrive."
  },
]

export function MiddleBloc() {

  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  const sliderBreakpoints = {
    "1720": {
      slidesPerView: 4,
      spaceBetween: 10,
    },
    "1400": {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    "1025": {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    "768": {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    "500 ": {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    "0": {
      slidesPerView: 1,
      spaceBetween: 10,
    }
  }

  // Create an array of string
  let stringArray = Object?.keys(sliderBreakpoints);
  let numberArray = stringArray?.map(Number);
  let max = Math?.max(...numberArray);
  let maxValue = max?.toString();
  return (
    <animated.div style={props}>

      <div className={"grid grid-cols-1 gap-8"}>
        <Carousel
          breakpoints={sliderBreakpoints}
          buttonClassName="-mt-8 md:-mt-10"
          loop={
            sliderBreakpoints[maxValue]?.slidesPerView <=
            data?.length
          }
        >
          {Array.isArray(data) && (
            data.map((item, idx) => (
              <SwiperSlide key={`card--key-${idx}`}>
                <div key={`data-listen--${idx}`}>
                  <div className="flex flex-col items-center pb-6 justify-between bg-white border-b-2 shadow-xl hover:shadow-2xl rounded-xl max-h-[500px] space-y-2 w-full">
                    <img className='max-w-96 h-72 rounded-t-xl' src={item?.img} alt={item?.title} />
                    <h2 className="text-gray-800 text-2xl font-bold text-center">{item?.title}</h2>
                    <p className="text-gray-800 text-base text-center p-3">{item?.content.substr(0, 100)}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))
          )}
        </Carousel>
      </div>
    </animated.div>
  )
}

export function BlockWithImage() {
  return (
    <div>
      <h1 className="text-gray-800 text-5xl text-center py-8 px-4 lg:px-16 xl:px-32">Awesome feature you'll like,<br /> everything in a
        simple way</h1>

      <div className="hidden w-full px-6 bg-yellow-300 rounded-xl p-9">
        <div className="flex-1 justify-center items-center h-full w-full p-8">
          <h1 className="pb-4 text-4xl font-bold text-center">Our expertise Easily tracing your task, <br /> every day, every week <br /> with ease</h1>
          <p className="text-gray-800 text-base text-center">Tired of coming across tasks with this plain old row styles ? <br /> That
            boring preview just doesn't cut it !</p>
        </div>
      </div>
    </div>
  )
}

export function BlockInfos({
    title,
    description,
    tag,
    image,
    rightImage = false
}) {
    return (
        <div className="flex flex-wrap w-full space-y-4 lg:space-y-0">
            <div className={cn(
                "flex lg:justify-start justify-center items-center w-full lg:w-3/6 xl:w-6/12",
                rightImage ? "order-0 lg:order-last" : " lg:order-0 "
            )}>
                <div className="">
                    <img className="max-w-xs lg:max-w-md rounded-lg sh-animate-left" src={image} alt="Image" />
                </div>
            </div>
            <div className="flex flex-col lg:items-start items-center justify-center w-full lg:w-3/6 xl:w-6/12">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">{tag}</p>
                <h3 className="mt-2 text-2xl sm:text-left lg:text-4xl font-bold">{title}</h3>
                <p className="mt-5 text-lg text-gray-700 text-center lg:text-left">{description}</p>
            </div>
        </div>
    )
}
