export default function Footer() {
    return (
        <div className="bg-white w-full p-5 h-full pt-12 px-8">
            <div className="flex flex-wrap space-y-4 lg:space-y-0 justify-between pb-16">
                <div className="flex flex-col space-y-4 w-full lg:w-5/12">
                    <div className="flex items-center w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6 rounded-full text-rose-400 bg-rose-400">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6 rounded-full text-rose-100 bg-rose-100">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <a href="/" className="ml-2 text-gray-900 text-2xl">ai.emb</a>
                    </div>
                    <p className="pr-0 lg:pr-16 xl:pr-32">AI EMBedment Solutions is a company specialized in the integration of Artificial Intelligence
                        solutions and customer journey innovation.</p>
                </div>

                <div className="w-full lg:w-4/12">
                    <div className="flex flex-wrap space-y-4 lg:space-y-0 w-full">
                        <div className="flex flex-col space-y-2 w-full lg:w-1/2">
                            <span className="text-gray-900 text-base font-bold">Solutions</span>
                            <ul className="text-gray-500">
                                <li>
                                    <span>AI providing</span>
                                </li>
                                <li>
                                    <span>Apps develop</span>
                                </li>
                                <li>
                                    <span>Web develop</span>
                                </li>
                                <li>
                                    <span>Data scraping</span>
                                </li>
                                <li>
                                    <span>Big data</span>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="flex flex-col space-y-2  w-1/3">
                            <span className="text-gray-900 text-base font-bold">Services</span>
                            <ul className="text-gray-500 font-medium">
                                <li><a href="#experience">Pricing</a></li>
                                <li><a href="#projets">About</a></li>
                                <li><a href="#contact">Blog</a></li>
                            </ul>
                        </div> */}
                        <div className="flex flex-col space-y-2 w-full lg:w-1/2">
                            <span className="text-gray-900 text-base font-bold">Policy</span>
                            <ul className="text-gray-500">
                                <li>
                                    <a href="#">Contact us</a>
                                </li>
                                <li>
                                    <a href="#">Careers</a>
                                </li>
                                <li>
                                    <a href="#">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex w-full lg:w-3/12">
                    <div className="flex justify-start lg:justify-end  w-full">
                        <div>
                            <span className="text-gray-900 text-base font-bold">Let's Talk</span>
                            <ul className="text-gray-500 font-medium">
                                <li><a href="#experience">contact@ai-emb.com</a></li>
                                <li><a href="#projets">Benin, Abomey-Calavi</a></li>
                                <li><a href="#contact">+229 51225855</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap justify-between p-4 border-t pt-8">
                <div className="w-full lg:w-1/2 space-x-2">
                    <button className="button text-gray-800 text-base font-bold w-10 h-10 rounded-full border">
                    <a target="_blank" href="https://www.linkedin.com/company/ai-embedment-solutions" className="text-gray-800 text-base">in</a>
                    </button>
                </div>
                <div className="w-full lg:w-1/2">
                    <span className="text-gray-800 text-base">AI EMB. All right reserved. 2023</span>
                </div>
            </div>
        </div>
    )
}