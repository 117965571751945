import { useState } from "react"
import cn from "classnames";

const init = -1;


const data = [
  {
    title: "What services does AI Embedment Solutions provide?",
    content: "AI Embedment Solutions specializes in the seamless integration of Artificial Intelligence solutions and innovative customer journey enhancements for businesses across various industries."
  },
  {
    title: "How can AI benefit my business, and why should I consider embedding AI solutions?",
    content: "AI offers businesses the power to automate processes, gain valuable insights, and enhance customer experiences. Embedding AI solutions from AI Embedment Solutions ensures a competitive edge and efficiency gains."
  },
  {
    title: "What industries does AI Embedment Solutions cater to?",
    content: "We serve a diverse range of industries, including but not limited to finance, healthcare, e-commerce, and manufacturing, tailoring AI solutions to meet the unique needs of each sector."
  },
  {
    title: "How does AI Embedment Solutions ensure data security and privacy?",
    content: "Security is a top priority for us. AI Embedment Solutions implements robust measures to safeguard data, ensuring compliance with industry regulations and standards to protect the privacy of our clients and their customers."
  },
  {
    title: "Can AI solutions be customized to fit the specific requirements of my business?",
    content: "Absolutely! We understand that each business is unique. AI Embedment Solutions offers tailor-made solutions, working closely with clients to understand their goals and crafting AI integrations that align with their specific needs."
  },
  {
    title: "What sets AI Embedment Solutions apart from other AI integration providers?",
    content: "Our commitment to innovation, personalized solutions, and a customer-centric approach distinguishes AI Embedment Solutions. We focus on creating AI integrations that drive tangible value for our clients."
  },
  {
    title: "How does AI Embedment Solutions stay updated with the latest AI advancements?",
    content: "Our team of experts is dedicated to staying at the forefront of AI technology. We regularly engage in training, attend industry conferences, and foster partnerships to ensure we offer the latest and most advanced AI solutions."
  },
  {
    title: "What is the typical process for implementing AI solutions with AI Embedment Solutions?",
    content: "Our implementation process involves a comprehensive assessment of your business needs, custom solution design, seamless integration, and ongoing support. We prioritize transparency and collaboration at every stage."
  }
];

export default function Faq() {
  const [isOpen, setIsOpen] = useState(init);

  return (
    <div className="flex flex-wrap justify-between w-full px-4 md:px-8 xl:px-16 pt-8 h-full">

      <div className="flex flex-col justify-between w-full lg:w-2/6">
        <div className="mb-4">
          <h1 className="text-gray-800 text-4xl text-center md:text-left">Frequently Asked <br /> Questions</h1>
        </div>

        <div>
          <button className="hidden md:block text-gray-700 border border-gray-700 rounded-full py-3 px-12">Have other questions ?</button>
        </div>
      </div>

      <div className="w-full lg:w-4/6">
        {data.map(({title, content}, idx) => (
          <div className="flex flex-row items-center justify-between w-full border-b py-6">
            <div className="flex flex-col justify-between">
              <h1 className="text-gray-800 pb-4 text-xl cursor-pointer" onClick={() => setIsOpen(idx)}>{title}</h1>
              <p className={cn(
                "text-gray-800 pb-4 text-base cursor-pointer pr-0 md:pr-4 xl:pr-8",
                isOpen === idx ? "block transition-all duration-110 " : "hidden"
              )}>{content}</p>
            </div>
            <div className="flex flex-row justify-between">
              {!(isOpen === idx) ?
                <button className="" onClick={() => setIsOpen(idx)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-6 h-6 text-rose-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svg>
                </button>
                :
                <button className="" onClick={() => setIsOpen(init)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                  </svg>
                </button>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}