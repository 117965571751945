import Header from "components/Header";
import HeaderTop from "components/HeaderTop";
import Feature from "components/Feature";
import Footer from "components/Footer";
import Faq from "components/Faq";
import Values from "components/Values";
import CardAnimated from "components/CardAnimated";
import Title from "components/Title";
import TitleAnimated from "components/TitleAnimated";
import Parallax1 from "components/Parallax";
import { TechnoBlock, MiddleBloc, BlockWithImage, BlockInfos } from "./components/Block";
import { Fragment } from "react";
import React, { useEffect, useState, useRef } from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import styles from 'styles/styles.module.css'
import { useTrail, a } from '@react-spring/web'
import horizontal from "styles/horizontal.module.css";
import { useInView } from 'react-intersection-observer';
import { homeData } from "constants/data";

function App1() {
  const alignCenter = { display: 'flex', alignItems: 'center' }
  return (
    <div>
      <div className={styles.background} />

      <Parallax pages={5}>
        <ParallaxLayer offset={0} speed={0.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <p className={styles.scrollText}>Scroll down</p>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 1, end: 3 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.card} ${styles.sticky}`}>
            <p>I'm a sticky layer</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.5} speed={1.5} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.card} ${styles.parallax} ${styles.purple}`}>
            <p>I'm not</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.5} speed={1.5} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.card} ${styles.parallax} ${styles.blue}`}>
            <p>Neither am I</p>
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}

const Page = ({ offset, gradient, onClick, text }) => (
  <>
    <ParallaxLayer offset={offset} speed={0.2} onClick={onClick}>
      <div className={horizontal.slopeBegin} />
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.6} onClick={onClick}>
      <div className={`${horizontal.slopeEnd} ${horizontal[gradient]}`} />
    </ParallaxLayer>

    <ParallaxLayer className={`${horizontal.text} ${horizontal.number}`} offset={offset} speed={0.6}>
      {/* <h1> <span >0{offset + 1}</span></h1> */}
      <h1 className="ml-6 text-2xl lg:text-6xl font-bold"> {text}</h1>
    </ParallaxLayer>
  </>
)

const titles = [1, 2, 3, 4, 5, 6];

function App4() {
  const parallax = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [ref, inView] = useInView({
  //   triggerOnce: true, // Déclencher seulement une fois
  //   threshold: 0.9, // L'élément est considéré comme visible lorsque 10% de sa hauteur est dans la fenêtre
  // });

  const scroll = (to) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  }

  useEffect(() => {
    const clickItem = () => {
      if (currentIndex < titles.length) {
        scroll(currentIndex);
        setCurrentIndex((currentIndex + 1) % titles.length);
      }
    };
    const timer = setTimeout(clickItem, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex, titles]);

  return (
    <div
      ref={parallax}
      className={`overflow-x-auto overflow-y-hidden`}
      style={{ whiteSpace: 'nowrap', width: '100%', height: '100%', }}
    >
      <Parallax className={horizontal.container} ref={parallax} pages={6} horizontal>
        <Page offset={0} gradient="pink"    text="Enterprise Solutions Integration" onClick={() => scroll(1)} />
        <Page offset={1} gradient="teal"    text="Customer Experience Enhancement" onClick={() => scroll(2)} />
        <Page offset={2} gradient="tomato"  text="Healthcare Transformation" onClick={() => scroll(3)} />
        <Page offset={3} gradient="rose"    text="Education Technology Solutions" onClick={() => scroll(4)} />
        <Page offset={4} gradient="dink"    text="Cybersecurity Solutions" onClick={() => scroll(5)} />
        <Page offset={5} gradient="magento" text="Smart City Initiatives" onClick={() => scroll(0)} />
      </Parallax>
    </div>
  )
}

function Components() {
  return (
    <div className="flex flex-col px-2 mx-auto space-y-2 lg:space-y-4 w-full xl:px-4">
      {homeData.map((it, index) => (
        <BlockInfos
          title={it?.title}
          key={`list-block--${index}`}
          image={it?.image}
          tag={it?.tag}
          description={it?.description}
          rightImage={it?.rightImage}
        />
      ))}
    </div>
  )
}

function App() {

  return (
    <Fragment>
      <Header />
      <HeaderTop />
      <div className="space-y-16">
        <div className="px-4 lg:px-8 space-y-8 mt-8 lg:mt-0">
          <Title title="AI in your daily life" />
          <MiddleBloc />
        </div>

        <div className="hidden md:block space-y-2 h-[950px] lg:h-[750px]" id="about">
          <Title title="Our sectors of activity" />
          <App4 />
        </div>

        <div className="px-4 lg:px-32 mt-16 xl:mt-0" id="values">
          <Title title="Our values" />
          <Components />
        </div>

        {/* <div className="px-4 lg:px-8 mt-16 xl:mt-0" id="values">
          <Title title="Our values" />
          <Values />
        </div> */}

        <div className="space-y-4 py-4 md:py-16">
          <BlockWithImage />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 px-4 lg:px-8">
            <TechnoBlock img="./assets/images/bot.png" />
            <TechnoBlock img="./assets/images/chat.png" />
          </div>
          <div className="hidden flex flex-col w-full py-0 h-full">
            <Title title="Our sectors of activity" />
            <div className="flex flex-row items-center w-full px-0 mt-0 py-0">
              <div className="flex w-full md:w-1/3 pl-16">
                <TitleAnimated />
              </div>

              <div className="relative flex justify-end w-full md:w-2/3 overflow-sx-hidden">
                <div className="">
                  <img className="max-w-xl" src="./assets/images/steps.png" alt="TechnoBlock" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Title title="Our portfolio" />
        <CardAnimated /> */}

        <div className="space-y-8 my-16" id="partners">
          <Title title="Partners & Collaborators" />
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 items-center justify-center ">
            <div className="">
              <img className="h-24" src="./assets/images/shopinzen.png" alt="" />
            </div>
            <div className="">
              <img className="h-20 transform scale-110" src="./assets/images/ncv.png" alt="" />
            </div>
          </div>
        </div>


        <div className="hiddens space-y-8" id="contact">
          <Faq />
          <Feature />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default App;