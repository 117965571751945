const card = [
    {
        title: "What is SaaS? Software as a Service Explained",
        subtitle: "Going into this journey, I had a standard therapy regimen, based on looking at the research literature. After I saw the movie, I started to ask other people what they did for their anxiety, and some",
        img: "https://images.unsplash.com/photo-1604999565976-8913ad2ddb7c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
        btn: "Sidi dev",
        postback: "url"
    },
    {
        title: "A Quick Guide to WordPress Hosting",
        subtitle: "According to him, â€œI'm still surprised that this has happened. But we are surprised because we are so surprised.â€More revelations about Whittington will be featured in the film",
        img: "photo.jpg",
        btn: "Micheal",
        postback: "url"
    },
    {
        title: "7 Promising VS Code Extensions Introduced in 2022",
        subtitle: "I hope I remembered all the stuff that they needed to know. They're like, 'okay,' and write it in their little reading notebooks. I realized today that I have all this stuff that ",
        img: "https://images.unsplash.com/photo-1590523277543-a94d2e4eb00b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
        btn: "Luis",
        postback: "url"
    },
    {
        title: "How to Use Root C++ Interpreter Shell to Write C++ Programs",
        subtitle: "The powerful gravity waves resulting from the impact of the planets' moons â€” four in total â€” were finally resolved in 2015 when gravitational microlensing was used to observe the",
        img: "https://images.unsplash.com/photo-1559333086-b0a56225a93c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
        btn: "Lourin",
        postback: "url"
    },
    {
        title: "What is SaaS? Software as a Service Explained",
        subtitle: "Going into this journey, I had a standard therapy regimen, based on looking at the research literature. After I saw the movie, I started to ask other people what they did for their anxiety, and some",
        img: "photo.jpg",
        btn: "Sidi dev",
        postback: "url"
    },
    {
        title: "A Quick Guide to WordPress Hosting",
        subtitle: "According to him, â€œI'm still surprised that this has happened. But we are surprised because we are so surprised.â€More revelations about Whittington will be featured in the film",
        img: "https://images.unsplash.com/photo-1590523277543-a94d2e4eb00b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
        btn: "Micheal",
        postback: "url"
    },
    {
        title: "7 Promising VS Code Extensions Introduced in 2022",
        subtitle: "I hope I remembered all the stuff that they needed to know. They're like, 'okay,' and write it in their little reading notebooks. I realized today that I have all this stuff that",
        img: "photo.jpg",
        btn: "Luis",
        postback: "url"
    },
    {
        title: "How to Use Root C++ Interpreter Shell to Write C++ Programs",
        subtitle: "The powerful gravity waves resulting from the impact of the planets' moons â€” four in total â€” were finally resolved in 2015 when gravitational microlensing was used to observe the",
        img: "https://images.unsplash.com/photo-1559333086-b0a56225a93c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
        btn: "Lourin",
        postback: "url"
    },
    {
        title: "How to Use Root C++ Interpreter Shell to Write C++ Programs",
        subtitle: "The powerful gravity waves resulting from the impact of the planets' moons â€” four in total â€” were finally resolved in 2015 when gravitational microlensing was used to observe the",
        img: "https://images.unsplash.com/photo-1590523277543-a94d2e4eb00b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
        btn: "Lourin",
        postback: "url"
    }
];

export const homeData = [
    {
      title: "Innovation",
      description: "Innovation is not just a goal at AI Embedment Solutions; it's the very essence of our existence. As a company specializing in the integration of Artificial Intelligence solutions and customer journey innovation, we consistently push the boundaries of what's achievable in the tech landscape.",
      image: "./assets/icons/value-1.webp",
      tag: "Our main goal",
      rightImage: true
    },
    {
      title: "Collaboration",
      description: "Our commitment to innovation is deeply rooted in collaborative AI integration. We see ourselves not just as solution providers but as partners in progress. By working closely with our clients, we co-create AI solutions that seamlessly integrate into their unique business landscapes.",
      image: "./assets/icons/value-2.webp",
      tag: "Innovation to collaboration",
      rightImage: false
    },
    {
      title: "Devotion",
      description: "Our devotion to innovation takes center stage in the mastery of AI integration. We don't just implement solutions; we devote ourselves to understanding the intricacies of our clients' businesses.",
      image: "./assets/icons/value-3.webp",
      tag: "Innovation to devotion",
      rightImage: true
    }
];

export const pluginData = [
    {
      title: "Properly Set Up WordPress SEO Without Hiring an Expert",
      description: "AIOSEO makes it easy to set up the proper SEO foundations in less than 10 minutes. Our smart setup wizard optimizes your website’s SEO settings based on your unique profile.",
      image: "https://aioseo.com/wp-content/uploads/2020/11/homepage-thumbnail-onboarding-wizard.png",
      tag: "",
      rightImage: false
    },
    {
      title: "Easily Optimize Your Pages for Higher Rankings with TruSEO Score",
      description: "TruSEO Score gives you a more in-depth analysis into your optimization efforts than just a pass or fail. Our actionable checklist helps you to unlock maximum traffic with each page.",
      image: "https://aioseo.com/wp-content/uploads/2020/10/Frame-24.png",
      tag: "",
      rightImage: true
    },
    {
      title: "Seamless Integrations With All Your Favorite Tools",
      description: "All in One SEO offers seamless integration with popular social media platforms like Facebook, Twitter, Pinterest, and more.",
      image: "https://aioseo.com/wp-content/uploads/2020/11/aioseo-integrations.png",
      tag: "",
      rightImage: false
    },
    {
      title: "Track SEO Rankings and See Helpful SEO Metrics in One Place",
      description: "All in One SEO gives you valuable data and actionable insights effortlessly with our advanced Search Statistics feature right inside your WordPress dashboard.",
      image: "https://aioseo.com/wp-content/uploads/2023/05/1-Homepage-Section-1-Search-statistics-_-content-decay-reports-.png.webp",
      tag: "",
      rightImage: true
    }
];

const quick = [
    {
        title:"Finance"
    },
    {
        title:"Digital"
    },
    {
        title:"Product"
    }
];

export {
    card,
    quick
}
