import React, { useRef } from "react";
import { Swiper } from "swiper/react";
import { Navigation } from "swiper";
import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import "swiper/swiper-bundle.min.css";
import 'swiper/css/bundle';

const Carousel = ({
  children,
  className = '',
  buttonClassName = '',
  buttonSize = 'default',
  paginationVariant = 'default',
  breakpoints,
  loop,
  autoplay = {
    delay: 40,
  },
  buttonPosition = 'outside',
  showNavigation = true,
  ...props
}) => {
  const dir = 'ltr';
  const sliderRef = useRef();

  return (
    <div
      className={`carouselWrapper relative ${className} ${
        paginationVariant === 'circle' ? 'dotsCircle' : ''
      }`}
    >
      <Swiper
        loop={loop ?? true}
        autoplay={autoplay}
        breakpoints={breakpoints}
        onSwiper={it => (sliderRef.current = it)}
        modules={[Navigation]}
        {...props}
      >
        {children}
      </Swiper>
      {showNavigation ? (
        <div className="flex items-center w-full absolute top-2/4 z-10">
          <button
            onClick={() => sliderRef.current?.slidePrev()}
            aria-label="prev-button"
            className={`${buttonClassName} ${
              buttonSize === 'default'
                ? 'w-7 h-7 md:w-7 md:h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl'
                : 'w-7 h-7 md:w-7 md:h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg'
            } text-black flex items-center justify-center rounded-full text-white bg-rose-500 absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none ${
              buttonPosition === 'outside' && 'left-0'
            } transform ${
              dir === 'rtl'
                ? 'rotate-180 shadow-navigationReverse translate-x-1/2'
                : 'shadow-navigation -translate-x-1/2'
            }`}
          >
            <IoIosArrowBack />
          </button>
          <button
            onClick={() => sliderRef.current?.slideNext()}
            aria-label="next-button"
            className={`${buttonClassName} ${
              buttonSize === 'default'
                ? 'w-7 h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl'
                : 'w-7 h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg'
            } text-black flex items-center justify-center rounded-full bg-rose-500 text-white absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none ${
              buttonPosition === 'outside' && 'right-0'
            } transform ${
              dir === 'rtl'
                ? 'rotate-180 shadow-navigationReverse -translate-x-1/2'
                : 'shadow-navigation translate-x-1/2'
            }`}
          >
            <IoIosArrowForward />
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Carousel;
