import React, { useEffect, useCallback, useLayoutEffect, useState } from 'react'
import {
  useSpringRef,
  animated,
  useTransition,
  useSpring,
} from '@react-spring/web';
import styles from 'styles/img.module.css'
import { useTrail, a } from '@react-spring/web'
import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';

const activeClass = "text-rose-400 text-6xl lg:text-7xl cursor-pointer";
const inactiveClass = "text-gray-300 text-6xl lg:text-7xl cursor-pointer";

// Revolutionize Goals: Elevate Your Strategy with AI Embed.
const titles = [
  {
    title: "Revolutionize",
    image: "home.jpg"
  },
  {
    title: "Goals",
    image: "code.jpg"
  },
  {
    title: "Elevate Your",
    image: "coding.jpg"
  },
  {
    title: " Strategy with",
    image: "code.jpg"
  },
  // {
  //   title: "",
  //   image: "man.jpg"
  // },
  {
    title: "AI Embed",
    image: "code.jpg"
  }
];

function Images() {
  const [activeIndex, setActiveIndex] = useState(0)
  const springApi = useSpringRef()

  const transitions = useTransition(activeIndex, {
    from: {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
    },
    enter: {
      clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)',
    },
    leave: {
      clipPath: 'polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%)',
    },
    onRest: (_springs, _ctrl, item) => {
      if (activeIndex === item) {
        setActiveIndex(activeIndex === titles.length - 1 ? 0 : activeIndex + 1)
      }
    },
    exitBeforeEnter: true,
    config: {
      duration: 4000,
    },
    delay: 1000,
    ref: springApi,
  })

  const springs = useSpring({
    from: {
      strokeDashoffset: 120,
    },
    to: {
      strokeDashoffset: 0,
    },
    config: {
      duration: 11000,
    },
    loop: true,
    ref: springApi,
  })

  useLayoutEffect(() => {
    springApi.start()
  }, [activeIndex])

  return (
    <div className={styles.container}>
      <div className={styles.container__inner}>
        {transitions((springs, item) => (
          <animated.div className={styles.img__container} style={springs}>
            <img src={`./assets/images/${titles[item].image}`} className="bg-cover bg-right max-w-xl" alt="user" />
          </animated.div>
        ))}
        <div className={styles.ticker}>
          <div />
          <animated.svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            style={springs}>
            <path d="M19.9999 38.5001C17.5704 38.5001 15.1648 38.0216 12.9203 37.0919C10.6758 36.1622 8.63633 34.7995 6.91845 33.0816C5.20058 31.3638 3.83788 29.3243 2.90817 27.0798C1.97846 24.8353 1.49995 22.4296 1.49995 20.0002C1.49995 17.5707 1.97846 15.1651 2.90817 12.9206C3.83788 10.6761 5.20058 8.63663 6.91846 6.91875C8.63634 5.20087 10.6758 3.83818 12.9203 2.90847C15.1648 1.97876 17.5705 1.50024 19.9999 1.50024C22.4293 1.50024 24.835 1.97876 27.0795 2.90847C29.324 3.83818 31.3635 5.20088 33.0813 6.91876C34.7992 8.63663 36.1619 10.6761 37.0916 12.9206C38.0213 15.1651 38.4998 17.5707 38.4998 20.0002C38.4998 22.4296 38.0213 24.8353 37.0916 27.0798C36.1619 29.3243 34.7992 31.3638 33.0813 33.0816C31.3635 34.7995 29.324 36.1622 27.0795 37.0919C24.835 38.0216 22.4293 38.5001 19.9999 38.5001L19.9999 38.5001Z" />
          </animated.svg>
        </div>
      </div>
    </div>
  )
}

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className={styles.trailsText} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export default function HeaderTop() {

  const [currentImage, setCurrentImage] = useState(titles[0]);
  const [currentTitle, setCurrentTitle] = useState(titles[0].title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, set] = useState(true)

  const handleCurrentImage = useCallback((title, index) => {
    setCurrentImage(titles[index]);
    setCurrentTitle(titles[index]?.title);
  }, [currentTitle]);

  useEffect(() => {
    const clickItem = () => {
      if (currentIndex < titles.length) {
        document.getElementById(`title-h1-${currentIndex}`).click();
        setCurrentIndex((currentIndex + 1) % titles.length);
        setTimeout(() => {
          set(state => !state)
        }, 1000);
      }
    };
    const value = isMobileOnly ? 50000 : 5000;
    const timer = setTimeout(clickItem, value);
    return () => clearTimeout(timer);
  }, [currentIndex, titles]);

  const isActive = (item) => item.title === currentTitle;

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between pl-3 lg:pl-32 bg-gray-100">
        <div className="flex flex-col justify-between py-12">
          <div className="flex flex-col justify-between space-y-16 px-0 pt-2 md:pt-8 xl:pt-16">
            <div className="space-y-0 lg:space-y-1 cursor-none">
              {titles?.map((item, idx) => (
                <Trail open={open}>
                  <h1
                    key={`h1-title--${idx}`}
                    id={`title-h1-${idx}`}
                    className={cn(
                      "cursor-progress",
                      isActive(item) ? activeClass : inactiveClass
                    )}
                    onClick={() => handleCurrentImage(item?.title, idx)}
                  >
                    {item?.title}
                  </h1>
                </Trail>
              ))}
            </div>
            <div className="flex bg-black rounded-full hidden lg:block">
              <input
                type="email"
                placeholder="Enter your email"
                autocomplete="off"
                className="rounded-full py-3 px-12 placeholder:text-gray-400 text-md focus:outline-none"
              />
              <button className="text-white bg-black rounded-full py-3 px-16">Register</button>
            </div>
          </div>
          <div className='hidden lg:block'>
            <p className="text-gray-800 text-base">Boost your efficiency with our Productivity app!</p>
            <p className="text-gray-800 text-base">Get more done, effortlessy.</p>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className={cn(
            (isActive(currentImage)) ? "block" : "hidden",
          )}>
            <Images />
          </div>
        </div>
      </div>
      {/* <div className="absolute bottom-10 flex justify-center w-full">
        <a href="#about" class="rounded-full transform -translate-x-1/2 text-rose-500">
          <svg class="svg-inline--fa fa-circle-chevron-down text-[48px] w-12 h-12" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 480a224 224 0 1 1 0-448 224 224 0 1 1 0 448zM256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM132.7 235.3l112 112c6.2 6.2 16.4 6.2 22.6 0l112-112c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 313.4 155.3 212.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6z"></path></svg>
        </a>
      </div> */}
    </>
  )
}
