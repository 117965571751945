export default function Header() {


  function handleOpenNav() {
    document.getElementById("myNav").style.width = "100%";
  }

  function handleCloseNav() {
    document.getElementById("myNav").style.width = "0%";
  }

  return (
    <>
      <div id="myNav" className="overlay" style={{zIndex:10000}}>
        <a href="javascript:void(0)" className="closebtn" onClick={() => handleCloseNav()}>&times;</a>
        <div className="overlay-content">
          <a href="#about" onClick={() => handleCloseNav()}>About</a>
          <a href="#values" onClick={() => handleCloseNav()}>Values</a>
          <a href="#activity" onClick={() => handleCloseNav()}>Activity</a>
          <a href="#contact" onClick={() => handleCloseNav()}>Contact</a>
        </div>
      </div>
      <div className="flex flex-row justify-between sticky top-0 p-5 z-20 shadow-sm" id="bgBlur">

        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            className="w-6 h-6 rounded-full text-rose-400 bg-rose-400">
            <path strokeLinecap="round" strokeLinejoin="round"
              d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            className="w-6 h-6 rounded-full text-rose-100 bg-rose-100">
            <path strokeLinecap="round" strokeLinejoin="round"
              d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <a href="/" className="ml-2 text-gray-900 text-2xl">ai.emb</a>
        </div>

        <div>
          <div className="flex flex-end space-x-6 mr-8 font-regular">
            {/* <a href="#about">Features</a>
            <a href="#experience">Pricing</a>
            <a href="#projets">About</a>
            <a href="#contact">Blog</a> */}
          </div>
        </div>

        <div>
          <button onClick={() => handleOpenNav()} className="text-gray-700 rounded-full py-1.5 px-5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}