export default function Feature() {
    return (
        <div className="relative flex flex-col items-center justify-center space-y-4 bg-rose-500 p-4 h-96">
            <h1 className="text-white pb-4 text-4xl text-center md:text-left">Discover AI's Potential in Your Daily Life with AI EMB</h1>
            <p className="text-white text-base text-center md:text-left">Discover our industry-leading data, AI and cloud solutions. Contact us now to learn more and boost your business!</p>

            <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-0 lg:space-x-4 ">
                <button className="text-white border border-gray-50 cursor-pointer rounded-full py-3 px-12">Contact us</button>
                <button className="text-white border border-gray-50 cursor-pointer rounded-full py-3 px-12">Learn more</button>
            </div>

            <div className="absolute bottom-0 flex justify-between items-center w-full">
                <div className="h-96 w-96 bg-rose-50 opacity-25 rounded-full">
                </div>
                <div className="h-96 w-96 bg-rose-50 opacity-25 rounded-full">
                </div>
                <div className="h-96 w-96 bg-rose-50 opacity-25 rounded-full">
                </div>
            </div>
        </div>
    )
}