import React from 'react'
import ReactDOM from "react-dom"
import ErrorBoundary from "./components/ErrorBondary"
import App from './App'
import 'styles/index.css';
import 'styles/animate.css';
import 'styles/mycarousel.css';

const MyApp = () => {
  return(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>,
  document.getElementById('aiemb')
);
