export default function Title({ title }) {

    const splitStrings = title.split(" ");
    const string1 = splitStrings.slice(0, 1).join(" "); // Join the first two elements
    const string2 = splitStrings.slice(1).join(" "); // Join the remaining elements

    return (
        <h1 className="text-4xl lg:text-5xl text-center py-0 lg:py-4 xl:py-8 px-4 lg:px-16 xl:px-32 space-x-2">
            <span className="text-gray-800">{string1}</span>
            <span className="text-rose-800 font-bold">{string2}</span>
        </h1>
    )
}